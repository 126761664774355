<template>
  <a-select v-model:value="current" @search="filterNameInitial" :filterOption="filter" showSearch
    style="width: 200px;margin-right:10px;" :dropdownMatchSelectWidth="dropWith" dropdownClassName="drop">
    <a-select-option v-for="item in list" :value="item.id">
      <div class="option">
        <div class="name" :title="item.name">{{ item.name }}</div>
        <div class="description" :title="item.description">{{ item?.formula }}</div>
      </div>
    </a-select-option>
  </a-select>
</template>

<script>
import { Search } from "/src/api/prescription.js";
export default {
  data() {
    return {
      myFilter: "",
      data: [],
    };
  },
  created() {
  },
  mounted() {
    // alert(this.record.category)
    this.prescriptionType = this.$store.state.prescriptionType.find(item => item.dataField == this.record.category)
    this.getPrescriptionList()
  },
  activated() {

  },
  emits: ['update:modelValue'],
  //获取父元素传值
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    record: {
      type: Object,
      default: {},
    },
  },
  computed: {
    list() {
      // let list = this.$store.state.prescriptionList;
      let list = this.data;
      if (!this.myFilter) {
        return list.slice(0, 200);
      }
      return list.filter(item => item.name?.toLowerCase().includes(this.myFilter) || item.nameInitial?.toLowerCase().includes(this.myFilter)).slice(0, 200);
      // return [{ name: 1, id: 1 }]
    },
    current: {
      get() {
        return this.modelValue;
      },
      set(val) {
        console.log(val);
        this.$emit('update:modelValue', val);
      },
    },
    dropWith() {
      let item = this.list.find(item => item.formula)
      if (item) {
        return 500
      } else {
        return 200
      }
    }
  },
  methods: {
    filterNameInitial(input) {
      this.myFilter = input?.toLowerCase();
    },
    filter() {
      return true
    },
    async getPrescriptionList() {
      let obj = { includePrescription: true }
      console.log("获取列表前");
      console.log(this.prescriptionType);

      // obj.type = this.prescriptionType?.type
      // obj.category = this.prescriptionType?.category
      // if (this.record.category == "drugPair") {
      //   obj.type = "中药处方"
      //   obj.category = "药对"
      // } else if (this.record.category == "syndromeAcupoints") {
      //   obj.type = "穴位处方"
      //   obj.category = "穴位处方"
      // }
      obj.type = this.prescriptionType.prescriptionType
      obj.category = this.prescriptionType.prescriptionCategory
      console.log(obj);

      let res = await Search(obj);
      // console.log(res);

      if (res.errCode == 0) {
        this.data = res.data
      }

    },
  },
  watch: {
    record(val) {
      this.prescriptionType = this.$store.state.prescriptionType.find(item => item.aiCategory == val.category)
      this.getPrescriptionList()
    },
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}

.head span {
  font-weight: bold;
  margin-right: 10px;
}

.name {
  display: block;
  font-size: 14px;
}

.description {}

.description {
  display: block;
  color: #999;
  font-size: 8px;
  white-space: pre-wrap;
}
</style>